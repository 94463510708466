import React, { useEffect, useRef } from "react";
import NavigationButtons from "./NavigationButtons";
import styles from "../styles/Screen.module.css";
import homePageStyle from "../styles/HomePage.module.css";

const badgeColors = {
  DEVELOPMENT: '#0098C7',
  DEPLOYMENT: '#51386D',
  OPERATIONS: '#61A60E',
};

const subTitleColors = {
  DEVELOPMENT: '#0098C7',
  DEPLOYMENT: '#A68CC4',
  OPERATIONS: '#61A60E',
}


const VideoScreen = ({
  src,
  onNext,
  onPrevious,
  title,
  subtitle,
  developmentBadgeText,
  onGoToLanding,
  onGoToFirstContent
}) => {
  const videoRef = useRef(null);

  const getBadgeStyles = () => {
    const backgroundColor = badgeColors[developmentBadgeText.toUpperCase()] || '#038284'; // Default color
    return {
      backgroundColor,
      color: '#FFFFFF'
    };
  };

  const getSubtitleColor = () => {
    const backgroundColor = subTitleColors[developmentBadgeText.toUpperCase()] || '#038284'; // Default color
    return {
      backgroundColor,
      color: '#FFFFFF'
    };
  };

  const badgeStyles = getBadgeStyles();
  const subtitleStyles = getSubtitleColor();


  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleKeyPress = (e) => {
      if (e.code === "Space") {
        e.preventDefault();
        togglePlayPause();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    const handleVideoError = (e) => {
      console.error("Video playback error:", e.target.error);
    };

    video.addEventListener("error", handleVideoError);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      video.removeEventListener("error", handleVideoError);
    };
  }, []);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (!video) return;

    if (video.paused || video.ended) {
      video.play();
    } else {
      video.pause();
    }
  };

  return (
    <div className="container">
    <div className={styles.screen}>
      <header className={styles.headerSection}>
        <div className={styles.textContent}>

        
        <div className="row d-flex justify-content-between">
            <div className={`${homePageStyle.headersection}`}>

        <h1 className={styles.title}>{title}</h1>
          <p className={styles.subtitle} style={{ color: subtitleStyles.backgroundColor }}>{subtitle}</p>
       

        <div className={`${homePageStyle.homeiconsec}`}>
                <a href="#" rel="noreferrer" onClick={onGoToLanding}>
                  <img
                    src="/static/img/Frame.png"
                    className={`${homePageStyle.playicon}`}
                    alt="Play Icon"
                  />
                </a>
                <a href="#" rel="noreferrer" onClick={onGoToFirstContent}>
                  <img
                    src="/static/img/home.png"
                    className={`${homePageStyle.homeicon}`}
                    alt="Home Icon"
                  />
                </a>
              </div>
            </div>
          </div>
          

        {/* <div className={styles.icons}>
          <FontAwesomeIcon icon={faPlayCircle} className={styles.icon} onClick={onGoToLanding} />
          <FontAwesomeIcon icon={faHome} className={styles.icon} onClick={onGoToFirstContent} />
        </div> */}

        {developmentBadgeText && (
          <div className={styles.developmentBadgeContainer}>
            <div className={styles.developmentBadge} style={badgeStyles}>
              {developmentBadgeText}
            </div>
          </div>
        )}
        </div>
      </header>
      <main className={styles.mainContent}>
        <video
          ref={videoRef}
          className={styles.videoContent}
          controls
          autoPlay
          muted
          playsInline
          preload="auto"
          crossOrigin="anonymous"
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </main>
      <footer className={styles.footer}>
        <NavigationButtons onNext={onNext} onPrevious={onPrevious} />
      </footer>
    </div>
    </div>
  );
};

export default VideoScreen;