const screenData = [

    {
        type: 'image',
        src: "/static/viavi/3-Developer Sandbox.png",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: "image",
        src: "/static/viavi/4-Developer Sandbox.png",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: 'image',
        src: "/static/viavi/5-Developer Sandbox.png",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: 'image',
        src: "/static/viavi/6-Developer Sandbox.png",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "DEVELOPMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/7-Developer Sandbox.png",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: 'image',
        src: "/static/viavi/8-VMware RIC SDK.png",
        title: "VMware RIC SDK: Designed for Developers",
        subtitle: "Offers developer resources to accelerate building new xApps and rApps for VMware RIC",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: 'image',
        src: "/static/viavi/9-From Idea to Revenue.png",
        title: "From Idea to Revenue",
        subtitle: "Working with the best partners to build value",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: 'image',
        src: "/static/viavi/10-KPI Validation.png",
        title: "KPI Validation High level Functional Requirement and Solution",
        subtitle: "",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: 'video',
        src: "/static/viavi/11-Vision of RAN.mp4",
        title: "What is the Target and Vision of RAN Scenario Generator?",
        subtitle: "",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: 'video',
        src: "/static/viavi/12-Testbed as a Service-Development.mp4",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "DEVELOPMENT"
    },

    {
        type: 'image',
        src: "/static/viavi/13-14-Deployment.png",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },

    {
        type: 'image',
        src: "/static/viavi/15-VMware RIC Platform.png",
        title: "VMware RIC Platform",
        subtitle: "MULTI-CLOUD, MULTI-RAN, MULTI-APP, CSP-READY RIC PLATFORM",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/16-VMware RIC Platform.png",
        title: "VMware RIC Platform",
        subtitle: "MULTI-CLOUD, MULTI-RAN, MULTI-APP, CSP-READY RIC PLATFORM",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/17-VMware RIC Platform.png",
        title: "VMware RIC Platform",
        subtitle: "MULTI-CLOUD, MULTI-RAN, MULTI-APP, CSP-READY RIC PLATFORM",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/18-Digital Twin for Lab.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/19-Digital Twin for Lab.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/20-Digital Twin for Lab.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/21-Digital Twin for Lab.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/22-Digital Twin for Lab.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/23-Digital Twin for Lab.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/24-App Benchmarking.png",
        title: "App Benchmarking",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },


    {
        type: 'image',
        src: "/static/viavi/25-Conflict Mitigation.png",
        title: "Conflict Mitigation",
        subtitle: "​Big Data, Patterns and Analyzing trends",
        developmentBadgeText: "DEPLOYMENT"
    },

    {
        type: 'video',
        src: "/static/viavi/26-TBaaS-DTwin-Deployment.mp4",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "DEPLOYMENT"
    },

    {
        type: 'image',
        src: "/static/viavi/27-28-Operations.png",
        title: "Testbed as a Service",
        subtitle: "",
        developmentBadgeText: "OPERATIONS"
    },

    {
        type: 'image',
        src: "/static/viavi/29-Digital Twin for Lab - Operations.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "OPERATIONS"
    },
    {
        type: 'image',
        src: "/static/viavi/30-Digital Twin for Lab - Operations.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "OPERATIONS"
    },

    {
        type: 'image',
        src: "/static/viavi/31-Digital Twin for Lab - Operations.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "OPERATIONS"
    },

    {
        type: 'image',
        src: "/static/viavi/32-Digital Twin for Lab - Operations.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "OPERATIONS"
    },

    {
        type: 'image',
        src: "/static/viavi/33-Digital Twin for Lab - Operations.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "OPERATIONS"
    },

    {
        type: 'image',
        src: "/static/viavi/34-Digital Twin for Lab - Operations.png",
        title: "Digital Twin for Lab, Automated Deployment, Field & Planning",
        subtitle: "",
        developmentBadgeText: "OPERATIONS"
    },

];

export default screenData;