import React from 'react';
import styles from '../styles/LandingPage.module.css'; // Update the path to your new CSS module

const LandingPage = ({ onNext }) => {
  return (
    <div className={styles.screen} onClick={onNext}>
      <video autoPlay loop muted className={styles.fullScreenContent}>
        <source src="/static/viavi/0-VIAVI-V4 1 2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default LandingPage;
