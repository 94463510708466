import React from "react";
import styles from "../styles/Screen.module.css";
import NavigationButtons from "./NavigationButtons";
import homePageStyle from "../styles/HomePage.module.css";

const badgeColors = {
  DEVELOPMENT: '#0098C7',
  DEPLOYMENT: '#51386D',
  OPERATIONS: '#61A60E',
};

const subTitleColors = {
  DEVELOPMENT: '#0098C7',
  DEPLOYMENT: '#A68CC4',
  OPERATIONS: '#61A60E',
}

const ImageScreen = ({
  src,
  onNext,
  onPrevious,
  title,
  subtitle,
  developmentBadgeText,
  onGoToLanding, 
  onGoToFirstContent
}) => {
  const getBadgeStyles = () => {
    const backgroundColor = badgeColors[developmentBadgeText.toUpperCase()] || '#038284'; // Default color
    return {
      backgroundColor,
      color: '#FFFFFF'
    };
  };

  const getSubtitleColor = () => {
    const backgroundColor = subTitleColors[developmentBadgeText.toUpperCase()] || '#038284'; // Default color
    return {
      backgroundColor,
      color: '#FFFFFF'
    };
  };

  const badgeStyles = getBadgeStyles();
  const subtitleStyles = getSubtitleColor();

  return (
    <div className="container">
    <div className={styles.screen}>
      <header className={styles.headerSection}>
      <div className={styles.textContent}>
        
        <div className="row d-flex justify-content-between">
            <div className={`${homePageStyle.headersection}`}>
      
        
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.subtitle} style={{ color: subtitleStyles.backgroundColor }}>{subtitle}</p>

          <div className={`${homePageStyle.homeiconsec}`}>
                <a href="#" rel="noreferrer" onClick={onGoToLanding}>
                  <img
                    src="/static/img/Frame.png"
                    className={`${homePageStyle.playicon}`}
                    alt="Play Icon"
                  />
                </a>
                <a href="#" rel="noreferrer" onClick={onGoToFirstContent}>
                  <img
                    src="/static/img/home.png"
                    className={`${homePageStyle.homeicon}`}
                    alt="Home Icon"
                  />
                </a>
              </div>
            </div>
          </div>
        
        {developmentBadgeText && (
          <div className={styles.developmentBadgeContainer}>
            <div className={styles.developmentBadge} style={badgeStyles}>
              {developmentBadgeText}
            </div>
          </div>
        )}
        </div>
      </header>
      <main className={styles.mainContent}>
        <img src={src} alt={title} className={styles.contentImage} />
      </main>
      <footer className={styles.footer}>
        <NavigationButtons onNext={onNext} onPrevious={onPrevious} />
      </footer>
    </div>
    </div>
  );
};

export default ImageScreen;
