import React, { useState } from 'react';
import LandingPage from './components/LandingPage';
import HomePage from './components/HomePage';
import ImageScreen from './components/ImageScreen';
import VideoScreen from './components/VideoScreen';
import screenData from './ScreenData';

const App = () => {
  const [screenIndex, setScreenIndex] = useState(0);

  const goToLandingScreen = () => {
    setScreenIndex(0);
};

const goToFirstContentScreen = () => {
    setScreenIndex(1);
};

const goToNextScreen = () => {
  setScreenIndex(prevIndex => prevIndex < screenData.length + 1 ? prevIndex + 1 : prevIndex);
};

  const goToPreviousScreen = () => {
    setScreenIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const onGoToDeployment = () => {
    setScreenIndex(12);
  };

  const onGoToOperations = () => {
    setScreenIndex(25);
  };

  const onGoToDevelopment = () => {
    setScreenIndex(2);
  };

  // Render screens based on the screenIndex
  const renderCurrentScreen = () => {
    switch (screenIndex) {
      case 0:
        return <LandingPage onNext={goToNextScreen} />;
      case 1:
        return <HomePage
          onGoToLanding={() => setScreenIndex(0)}
          onGoToFirstContent={() => setScreenIndex(1)}
          onGoToDeployment={onGoToDeployment}
          onGoToOperations={onGoToOperations}
          onGoToDevelopment={onGoToDevelopment}

        />;
      default:
        // Adjust index for screenData array
        const currentScreen = screenData[screenIndex - 2]; // Adjust by -2 because screenData starts after HomePage
        if (currentScreen.type === 'image') {
          return (
            <ImageScreen
              key={`image_${screenIndex}`}
              {...currentScreen}
              onNext={goToNextScreen}
              onPrevious={goToPreviousScreen}
              onGoToLanding={goToLandingScreen}
              onGoToFirstContent={goToFirstContentScreen}
            />
          );
        } else if (currentScreen.type === 'video') {
          return (
            <VideoScreen
              key={`video_${screenIndex}`}
              {...currentScreen}
              onNext={goToNextScreen}
              onPrevious={goToPreviousScreen}
              onGoToLanding={goToLandingScreen}
              onGoToFirstContent={goToFirstContentScreen}
            />
          );
        }
        break;
    }
  };

  return <div>{renderCurrentScreen()}</div>;
};

export default App;
