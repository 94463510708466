/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import homePageStyle from "../styles/HomePage.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

const HomePage = ({
  onGoToDeployment,
  onGoToOperations,
  onGoToDevelopment,
  onGoToLanding,
  onGoToFirstContent,
}) => {
  return (
    <>
      <section
        className={`${homePageStyle.navigation} ${homePageStyle.heading}`}
      >
        <div className="container">
          <div className="row d-flex justify-content-between">
          <div className={`${homePageStyle.headersection}`}>
            <div className="col testbed-header">
              <h1>Testbed as a Service</h1>
           
              <div className={`${homePageStyle.homeiconsec}`}>
              <a href="#" rel="noreferrer" onClick={onGoToLanding}>
                <img
                  src="/static/img/Frame.png"
                  className={`${homePageStyle.playicon}`}
                  alt="Play Icon"
                />
              </a>
              <a href="#" rel="noreferrer" onClick={onGoToFirstContent}>
                <img
                  src="/static/img/home.png"
                  className={`${homePageStyle.homeicon}`}
                  alt="Home Icon"
                />
              </a>
            </div>
          </div>
          </div>
        </div>
        </div>
      </section>
      <div className={`${homePageStyle.container} ${homePageStyle.circleBox}`}>
        <div className="row d-flex justify-content-center">
          <div className={`${homePageStyle.circle}`}>
            <button
              className={`${homePageStyle.button}`}
              onClick={onGoToDevelopment}
            >
              Development
            </button>

            <button
              className={`${homePageStyle.button}`}
              onClick={onGoToOperations}
            >
              Operations
            </button>
            <button
              className={`${homePageStyle.button}`}
              onClick={onGoToDeployment}
            >
              Deployment
            </button>
            <div className={`${homePageStyle["button-container"]}`}>
              <img
                  src="/static/home/HomePageCenter.png"
                  className={`${homePageStyle.vm}`}
                  alt="circleplaceholder"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
