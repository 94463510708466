import React from "react";
import styles from "../styles/NavigationButtons.module.css"; // Update this path if necessary
const NavigationButtons = ({ onNext, onPrevious, isNextDisabled }) => {
  return (
    <div className={styles.navigationButtons}>
      <button onClick={onPrevious} className={styles.button}>
        Back
      </button>
      <button
        onClick={onNext}
        className={`${styles.button} ${isNextDisabled ? styles.disabled : ""}`}
        disabled={isNextDisabled}
      >
        Next
      </button>
      {/* Include any other control buttons here */}
    </div>
  );
};

export default NavigationButtons;
